import axios from 'axios'

const SignupApi = (data, onSignupSuccess, setErrors, setAlertMessage, createSignupErrorSchema, setSubmitting) => {
  axios
    .post(`${process.env.GATSBY_API_ENDPOINT}/Signup/CreateAccount`, data)
    .then(response => {
      const result = response.data.Result

      if (response !== null && result !== null) {
        onSignupSuccess(response.data, setErrors, setAlertMessage)
      } else {
        setErrors(createSignupErrorSchema(response.data))
      }
    })
    .catch(error => {
      setErrors(createSignupErrorSchema(error.data))
    })
    .then(() => {
      setSubmitting(false)
    })
}

export default SignupApi