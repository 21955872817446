const validations = new Function()
const allowTestCards = true

validations.validatePhoneNumber = function(phoneNumber) {
  const regPhoneNumber = /^\d{7,}$/
  return validations.validateRegEx(
    regPhoneNumber,
    phoneNumber.trim().replace(/[\s()+\-\.]|ext/gi, "")
  )
}

validations.validatePhoneExtension = function(phoneExt) {
  // console.log("the extension", phoneExt)
  const regPhoneExt = /^[0-9]*$/
  return validations.validateRegEx(regPhoneExt, phoneExt.trim())
}

validations.validateZipCode = function(countryCode, zipCode) {
  // console.log(countryCode)
  // var regexZipCode = {
  //   canada: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i, //i for case-insensitive
  //   usa: /^\d{5}(-\d{4})?$/,
  // }

  // var regExZipCodeForCountry = null
  // switch (countryCode.trim()) {
  //   case "US":
  //     regExZipCodeForCountry = regexZipCode.usa
  //     break
  //   case "CA":
  //     regExZipCodeForCountry = regexZipCode.canada
  //     break
  //   default:
  //     console.log("default")
  //     break
  // }

  // if (regExZipCodeForCountry !== null) {
  //   return validations.validateRegEx(regExZipCodeForCountry, zipCode.trim())
  // } else {
  //   return true
  // }
  return true
}

validations.validateCCHolderName = function(ccHolderName) {
  var regexValidCCHolderName = /^[A-Za-z.' -]+$/g
  return validations.validateRegEx(regexValidCCHolderName, ccHolderName.trim())
}

validations.validateCreditCardNumber = function(ccNumber) {
  ccNumber = ccNumber.trim()

  if (!allowTestCards) {
    if (
      ccNumber === "378282246310005" ||
      ccNumber === "6011111111111117" ||
      ccNumber === "3566 111111111113" ||
      ccNumber === "3566111111111113" ||
      ccNumber === "5555555555554444" ||
      ccNumber === "4111111111111111" ||
      ccNumber === "2535242573903224"
    )
      return false
  }

  const firstDigit = ccNumber.substring(0, 1)
  let regexValidCCNumber = null
  if (firstDigit === "4") {
    // VISA
    // Visa: length 16, prefix 4, dashes optional.
    regexValidCCNumber = /^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/
  } else if (firstDigit === "5") {
    // Mastercard: length 16, prefix 51-55, dashes optional.
    regexValidCCNumber = /^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$/
  } else if (firstDigit === "2") {
    // Mastercard: length 16, prefix 222100-272099, dashes optional.
    regexValidCCNumber = /^(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)-?\d{4}-?\d{4}-?\d{4}$/
  } else if (firstDigit === "6") {
    // Discover: length 16, prefix 6011, dashes optional.
    regexValidCCNumber = /^6011-?\d{4}-?\d{4}-?\d{4}$/
  } else if (firstDigit === "3") {
    // American Express: length 15, prefix 34 or 37.
    regexValidCCNumber = /^3[4,7]\d{13}$/
  } else {
    return false
  }

  if (regexValidCCNumber === null) return false
  if (!validations.validateRegEx(regexValidCCNumber, ccNumber)) return false
  // Remove all dashes for the checksum checks to eliminate negative numbers
  ccNumber = ccNumber.split("-").join("")
  // Checksum ("Mod 10")
  // Add even digits in even length strings or odd digits in odd length strings.
  var checksum = 0
  for (var i = 2 - (ccNumber.length % 2); i <= ccNumber.length; i += 2) {
    checksum += parseInt(ccNumber.charAt(i - 1))
  }
  // Analyze odd digits in even length strings or even digits in odd length strings.
  for (var i = (ccNumber.length % 2) + 1; i < ccNumber.length; i += 2) {
    var digit = parseInt(ccNumber.charAt(i - 1)) * 2
    if (digit < 10) {
      checksum += digit
    } else {
      checksum += digit - 9
    }
  }
  if (checksum % 10 === 0) return true
  else return false
}

validations.validateCreditCardExpiry = function(ccExpiryYear, ccExpiryMonth) {
  var today = new Date()
  if (ccExpiryYear < today.getFullYear()) {
    return false
  } else if (ccExpiryYear === today.getFullYear()) {
    if (ccExpiryMonth < today.getMonth() + 1) {
      return false
    }
  }
  return true
}

validations.validateCVCCode = function(ccNumber, cvcCode) {
  var valChar = "0123456789"
  if (ccNumber.substring(0, 1) === "3") {
    if (cvcCode.length !== 4) return false
  } else {
    if (cvcCode.length !== 3) return false
  }

  for (var i = 0; i < cvcCode.length; i++) {
    var c = cvcCode.charAt(i)
    if (valChar.indexOf(c) === -1) return false
  }
  return true
}

validations.validateRegEx = function(expression, value) {
  const objRegExp = new RegExp(expression)
  return objRegExp.test(value)
}

// validations.isStringNullOrEmpty = function(stringValue) {
//   if (stringValue === null) return true
//   var type = typeof stringValue
//   if (type != "string" && type != "number") return true
//   return stringValue.toString().trim().length < 1
// }

export default validations
